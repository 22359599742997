<template>
  <div>
  
    <button
      class="btn btn-default btn-sm mt-5 px-5"
      @click="this.$router.go(-1)"
      v-if="$guards.is_super_admin()"
    >
      Back
    </button>


    <div class="row" >
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-12">
            <div class="card shadow-lg w-100 border-0 mb-0" id="BusinessInfo">
              <div class="card-body px-lg-5 py-lg-3">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Business Information</h3>
                    <hr class="my-0 mb-3" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <strong
                      ><em class="fas fa-signature mr-2"></em> Name:
                    </strong>
                    <span>{{ data.name }}</span>
                    <br />
                    <br />
                    <strong
                      ><em class="fas fa-paper-plane mr-2"></em> Email:
                    </strong>
                    <span>{{ data.email }}</span>
                    <br />
                    <br />
                    <strong
                      ><em class="fas fa-link mr-2"></em> Website:
                    </strong>
                    <span>{{ data.website }}</span>
                    <br />
                    <br />
                  </div>
                  <div class="col-md-6">
                    <strong><em class="fas fa-phone mr-2"></em> Phone: </strong>
                    <span>{{ data.phone }}</span>
                    <br />
                    <br />
                    <strong
                      ><em class="fas fa-location-arrow mr-2"></em> Address:
                    </strong>
                    <span>{{ data.address }}</span>
                  </div>
                   <div class="col-md-12">
                    <strong><em class="fas fa-eye mr-2"></em> Categories: </strong>
                    <span v-for="category in data.categories" :key="category" class="badge badge-dot m-1">{{ category }}, </span>
                  
                    <br />
                    <strong
                      ><em class="fas fa-location-arrow mr-2"></em> Address:
                    </strong>
                    <span>{{ data.address }}</span>
                  </div> 
                  <div class="col-md-12 mt-2">
                    <strong
                      ><em class="fas fa-info-circle mr-2"></em>Business Description
                    </strong>
                    <p class="text-muted p-2" v-html="data.about"></p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
    
      </div>
      <div class="col-md-4">
        <!-- Card to for suspention -->
        <div
          class="card shadow-lg w-100 border-0 mb-0 mb-4"
          v-if=" approval_status === 'Suspended'"
        >
          <div class="card-body px-lg-2 py-lg-3">
            <div class="row">
              <div class="col-md-12">
                <h3>Suspended for below reason</h3>
                <hr class="my-0 mb-3" />
                <p class="bg-warning p-3">
                  {{ reason }}
                </p>
                <button
                  class="mt-4 btn btn-default"
                  @click="approve_from_supension"
                  v-if="$guards.is_super_admin() || $guards.is_Admin()"
                >
                  <em class="ni ni-check-bold"></em> Un-Suspend
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card shadow-lg w-100 border-0 mb-0"
          id="BusinessInfo"

        >
          <div class="card-body px-lg-5 py-lg-3">
            <div class="row">
              <div class="col-md-12">
                <h3>Actions</h3>
                <hr class="my-0 mb-3" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12" v-if="!$guards.is_super_admin() && !$guards.is_Admin()">
                <router-link
                  type="button"
                  class="btn btn-outline-primary btn-sm btn-block w-100 "
                  :to="{
                    name: 'Entity Upload Images',
                    params: { uuid: this.$route.params.uuid },
                  }"
                >
                  Manage Images
                </router-link>
              </div>
            </div>
            <div
              class="row mb-2"
             
            >
              <div class="col-md-12" v-if="!$guards.is_super_admin() && !$guards.is_Admin()">
                <router-link
                  type="button"
                  class="btn btn-outline-primary btn-sm btn-block w-100 "
                  :to="{
                    name: 'Update Business',
                    params: { uuid: this.$route.params.uuid },
                  }"
                >
                  Update Entity
                </router-link>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-md-12">
                <button
                  v-if="
                    ($guards.is_super_admin() || $guards.is_Admin()) && approval_status !== 'Suspended'
                  "
                  type="button"
                  class="btn btn-warning btn-sm btn-block w-100"
                  data-toggle="modal"
                  data-target="#suspendModal"
                >
                  Suspend Entity
                </button>
              </div>
            </div>

          </div>
        </div>

        <!-- end of approval reason -->
      </div>
    </div>

    <!-- Modal  For Suspention Reason-->
    <div
      class="modal fade"
      id="suspendModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="suspendModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Suspense Reason</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @close="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body bg-secondary">
            <div class=" col-md-12 text-danger">
              <strong>After suspending this entity it will no longer show on home page</strong>
              <hr/>
            </div>
            <div class="form-group col-md-12">
              <label class="form-control-label"
                >Please Add the reason to suspend this entity</label
              >
              <textarea
                class="form-control form-control-alternative"
                placeholder="Reason for suspention"
                rows="5"
                v-model="suspend_reason.value"
              ></textarea>
              <label
                v-for="error in suspend_reason.errors"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="cancelbtn"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-warning"
              @click="suspend_entity"
            >
              Suspend
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end suspenction reason -->

    <!-- Modal  For Adding the map url-->
    <div
      class="modal fade"
      id="mapModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mapModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ map.title }} map url
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @close="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body bg-secondary">
            <div class="form-group col-md-12">
              <label class="form-control-label">Map Url</label>
              <textarea
                class="form-control form-control-alternative"
                placeholder="Add Map Url"
                v-model="map.url.value"
                rows="8"
              />
              <label
                v-for="error in map.url.errors"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="mapCancelbtn"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-default" @click="add_map_url">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end of add map url  -->

    <!-- Modal  For Adding the Keywork url-->
    <div
      class="modal fade"
      id="keywordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="keywordModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add keyword</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @close="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body bg-secondary">
            <div class="form-group col-md-12">
              <label class="form-control-label">keyword</label>
              <input
                class="form-control form-control-alternative"
                placeholder="Add keyword"
                type="text"
                v-model="keyword.value"
              />
              <label
                v-for="error in keyword.errors"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="keywordCancelbtn"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-default" @click="add_keyword">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end of add keyword url  -->

    <!-- Modal  For Updating the Keywork url-->
    <div
      class="modal fade"
      id="keywordModalUpdate"
      tabindex="-1"
      role="dialog"
      aria-labelledby="keywordModalLabelUpdate"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update keyword</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @close="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body bg-secondary">
            <div class="form-group col-md-12">
              <label class="form-control-label">keyword</label>
              <input
                class="form-control form-control-alternative"
                placeholder="Update keyword"
                type="text"
                v-model="keywordUpdate.value"
              />
              <label
                v-for="error in keyword.errors"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="keywordCancelbtnUpdate"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-default"
              @click="update_keyword"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end of update keyword url  -->

    <!-- Modal  For Update social-->
    <div
      class="modal fade"
      id="socialModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="socialModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update {{ social.title.value }} Link
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @close="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body bg-secondary">
            <div class="form-group col-md-12">
              <label class="form-control-label">Link</label>
              <input
                class="form-control form-control-alternative"
                placeholder="Add the link"
                type="text"
                v-model="social.link.value"
              />
              <label
                v-for="error in social.link.errors"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="socialCancelbtn"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-default"
              @click="update_social"
            >
              Update Link
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end of update  -->
  </div>
</template>
<script>
import axios from "axios";
import { alert } from "../../utils/alertUtils";
// import DataTable from "@/components/Datatable";

export default {
  name: "EntityDetail",
  components: {
    // "data-table": DataTable,

  },
  
  data() {
    return {
      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "",
            btnClass: "btn btn-danger btn-sm",
            iconClass: "fa fa-trash-alt",
            btnFn: (uuid) => {
              this.delete_keyword(uuid);
            },
            btnParamName: "uuid",
          },
          {
            btnText: "",
            btnClass: "btn btn-default btn-sm edit_keyword",
            iconClass: "fa fa-edit",
            btnFn: (uuid) => {
              this.get_keyword(uuid);
              document.getElementById("updateKeyword").click();
              // this.$router.push({name:'ResourceDetail', params:{uuid:uuid}})
            },
            btnParamName: "uuid",
          },
        ],
        columns: [{ title: "Keyword", data: "keywordName" }],
        extra_options: {},
      },
      suspend_reason: {
        value: "",
        errors: [],
      },
      keyword: {
        value: "",
        errors: [],
      },
      keywordUpdate: {
        value: "",
        errors: [],
      },
      social: {
        title: {
          value: "",
          errors: [],
        },
        link: {
          value: "",
          errors: [],
        },
      },
      map: {
        title: "",
        url: {
          value: "",
          errors: [],
        },
      },
      keyword_table_loaded: false,
      keywords: [],
      contacts: [],
      visible: false,
      socials: [],
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      data: {},
      entity_id: null,
      facebookLink: "",
      twitterLink: "",
      instagramLink: "",
      facebookIn: false,
      twitterIn: false,
      instagramIn: false,
      approval_status: "",
      reason: "", //suspenction reason to be to validate
      keywordUuid: "",
      socialUuid:"",
      category:{}
    };
  },
  methods: {
    get_keywords: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/keywords`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          // this.keywords = res.data;
          this.dtb_options.data = res.data;
          this.keyword_table_loaded = true;
         
        })
        .catch((err) => {
           if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                 this.$store.dispatch("logout");
           }
        });
    },

    get_keyword: function (uuid) {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/keywords/${uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.keywordUpdate.value = res.data.keywordName;
          this.keywordUuid = res.data.uuid;
        })
        .catch((err) => {
           if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                 this.$store.dispatch("logout");
           }
        });
    },

    add_keyword: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/keywords`;
      axios
        .post(
          url,
          { keywordName: this.keyword.value, entity: this.entity_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then(() => {
          alert.success_center("You have successfully added Keyword");
          this.keyword.value = "";
          this.keyword.errors = [];
          document.getElementById("keywordCancelbtn").click();

        })
        .catch((err) => {
          this.keyword.errors = err.response.data;
           if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                this.$store.dispatch("logout");
           }else{
              alert.error(
                "An error occured on getting Social Network link, please try again,if the error persist kindly contact the owner of this site!"
              );
           }
        });
    },

    delete_keyword: function (uuid) {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/keywords/${uuid}`;

      axios
        .delete(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then(() => {
          alert.success_center("You have successfully Deleted Keyword");
          this.get_keywords();
        })
        .catch((err) => {
           if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                 this.$store.dispatch("logout");
           }else{
              alert.error(
                "An error occured on deleting  Keyword link, please try again,if the error persist kindly contact the owner of this site!"
              );
           }
        });
    },

    update_keyword: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/keywords/${this.keywordUuid}`;
      axios
        .put(
          url,
          { keywordName: this.keywordUpdate.value, entity: this.entity_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then(() => {
          alert.success_center("You have successfully updated Keyword");
          this.get_keywords();
          document.getElementById("keywordCancelbtnUpdate").click();
        })
        .catch((err) => {
          this.keywordUpdate.errors = err.response.data;
           if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                this.$store.dispatch("logout");
           }else{
            alert.error(
              "An error occured on updating  Keyword link, please try again,if the error persist kindly contact the owner of this site!"
            );
           }
        });
    },

    get_entity: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
         
          this.fetch_categories(res.data)
          this.data.logo=res.data.logo
          this.data.name = res.data.name;
          this.data.email = res.data.email;
          this.data.phone = res.data.phone;
          this.data.address = res.data.address;
          this.data.website = res.data.website;
          this.data.description = res.data.description;
          this.data.about = res.data.about;
          this.data.no_of_products = res.data.no_of_products;
          this.data.no_of_services = res.data.no_of_services;
          this.data.no_of_employees = res.data.no_of_employees;
          this.data.no_of_women_employees = res.data.no_of_women_employees;
          this.data.is_rdb_registered =
            res.data.is_rdb_registered == true ? "Yes" : "No";
          this.data.target_market = res.data.target_market;
          this.data.has_rbs_certificate =
            res.data.has_rbs_certificate == true ? "Yes" : "No";
          this.data.does_delivery =
            res.data.does_delivery == true ? "Yes" : "No";
          this.data.accepted_electronic_payments =
            res.data.accepted_electronic_payments;
          this.data.business_type = res.data.business_type;
          this.data.starting_year = res.data.starting_year;
          this.data.industry = res.data.industry
            ? res.data.industry.name
            : "N/A";
          this.visible = res.data.visibility_status;
          this.approval_status = res.data.approval_status;
          this.reason = res.data.suspense_reason;
          this.map.url.value = res.data.map_url ? res.data.map_url : "";
          this.map.title = res.data.map_url ? "Update" : "Add";
          this.entity_id = res.data.id;
          this.data.categories=res.data.categories
        })
        .catch((err) => {
            if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
               this.$store.dispatch("logout");
           }
        });
    },



    approve_from_supension: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/normal_approval/`;
      axios
        .put(url, {},
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
        )
        .then(() => {
          alert.success_center("The entity has been approved successfully");
          this.get_entity();
        })
        .catch((err) => {
            if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                 // this.$store.dispatch("logout");
           }else{
              alert.error(
                "An error occured please try again,if the error persist kindly contact the owner of this site!"
              );
           }
        });
    },

    suspend_entity: function () {
      if (this.suspend_reason.value.length == 0) {
        this.suspend_reason.errors = ["This field can't be empty"];
      } else {
        var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/entity_Suspend/`;
        axios
          .put(
            url,
            { reason: this.suspend_reason.value },
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getToken}`,
              },
            }
          )
          .then(() => {
            alert.success_center("The entity has been suspended successfully");
            // this.$emit('close')
            this.get_entity();
            document.getElementById("cancelbtn").click();
          })
          .catch((err) => {
             if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                  // this.$store.dispatch("logout");
            }else{
              alert.error(
                "An error occured please try again,if the error persist kindly contact the owner of this site!"
              );
            }
          });
      }
    },
    // Method to add or update the map url for this Entity
    add_map_url: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}/entity/update_map_url/`;
      axios
        .put(
          url,
          { url: this.map.url.value },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success_center("You have successfully added map url");
          this.get_entity();
          document.getElementById("mapCancelbtn").click();
        })
        .catch((err) => {
           if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                 this.$store.dispatch("logout");
           }else{
              alert.error(
                "An error occured on getting Social Network link, please try again,if the error persist kindly contact the owner of this site!"
              );
           }
        });
    },


    fetch_categories: function (entity) {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/parentCategories`)
        .then((res) => {
         
          this.categories=res.data
          this.categories.forEach((el)=>{
            if(el.id==entity.category){
              this.category=el
            }
          })
          
        })
        .catch((err) => {
          if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                   this.$store.dispatch("logout");
                }
        });
    },
    
  },
  created() {
    this.get_entity();
  },
};
</script>
<style lang="css" >
#keywords_length {
  display: none;
}
#keywords_info {
  display: none;
}
</style>